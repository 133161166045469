import React from "react";
import Layout from "../../areas/main/components/Layout";
import NavOne from "../../areas/main/components/NavOne";
import Texts from "../../utils/lang-utils";
import Intro from "./components/Intro";
import Why from "./components/Why";
import Footer from "../../areas/main/components/Footer"
import Screenshots from "./components/Screenshots";
import Optional from "./components/Optional";
import OtherServices from "./components/OtherServices";

export default () => <Layout pageTitle={Texts.siteTitle} language="en">
    <NavOne catchScroll={false} />
    <Intro/>
    <Why/>
    <Optional />
    <Screenshots />
    <OtherServices />
    <Footer />
</Layout>;