import React from "react";
import Texts from "../../../utils/lang-utils";
import stamp from '../../../areas/main/assets/images/stamp.png'
import onboardingDemo from '../../../areas/main/assets/videos/eatella-onboarding-demo.mp4'
import urls from "../../../areas/main/assets/urls";

const Why = () => {
  return (
    <><section className="cta-one">
      <div className="container">
        <div className="cta-one__content">
          <div className="block-title text-center">
            <h2 className="block-title__title" dangerouslySetInnerHTML={{ __html: Texts.QRMenu.WhyTitle }}></h2>
          </div>

        </div>
        <div className="row">

          <div className=" col-md-6">
            <h4><i className="fa fa-check"></i> {Texts.QRMenu.WhyBullet1Title}</h4>

            <p>{Texts.QRMenu.WhyBullet1Text}</p>
            <h4><i className="fa fa-check"></i> {Texts.QRMenu.WhyBullet2Title}</h4>
            <p>{Texts.QRMenu.WhyBullet2Text}</p>
            <h4><i className="fa fa-check"></i> {Texts.QRMenu.WhyBullet3Title}</h4>
            <p>{Texts.QRMenu.WhyBullet3Text}</p>
          </div>

          <div className=" col-md-6">
            <h4><i className="fa fa-check"></i> {Texts.QRMenu.WhyBullet4Title}</h4>
            <p>{Texts.QRMenu.WhyBullet4Text}</p>
            <h4><i className="fa fa-check"></i> {Texts.QRMenu.WhyBullet5Title}</h4>
            <p>{Texts.QRMenu.WhyBullet5Text}</p>
            <h4><i className="fa fa-check"></i> {Texts.QRMenu.WhyBullet6Title}</h4>
            <p>{Texts.QRMenu.WhyBullet6Text}</p>
          </div>

        </div>
        <div>
          <div className="text-center mt-5">
            <a href={urls.onboarding} className="thm-btn">
              <span>{Texts.GetStarted}</span>
            </a>
            <img src={stamp} className="stamp" ></img>
          </div>

        </div>

      </div>
    </section>
      <section className="cta-two">
        <div className="container">
          <div className="cta-two__moc">
            {/* <img src={mocImage} style={{
                  borderRadius: 20,
                  boxShadow: '0px 20px 60px 0px rgb(244 151 190 / 80%)'
            }} /> */}


          </div>
          <div className="row h-100">


            <div className="col-lg-6">
              <div className="cta-two__content">
                <div className="block-title text-left">
                  <h2 className="block-title__title" dangerouslySetInnerHTML={{ __html: Texts.QRMenu.IsTitle }}></h2>
                </div>
                <h4><i className="fa fa-check"></i> {Texts.QRMenu.IsBullet1Title}</h4>
                <p dangerouslySetInnerHTML={{ __html: Texts.QRMenu.IsBullet1Text }}></p>
                <h4><i className="fa fa-check"></i> {Texts.QRMenu.IsBullet2Title}</h4>
                <p dangerouslySetInnerHTML={{ __html: Texts.QRMenu.IsBullet2Text }}></p>
                <a href={urls.onboarding} className="thm-btn mt-3 mobile-hide">
                  <span>{Texts.GetStarted}</span>
                </a>
              </div>
            </div>
            <div className="col-lg-6" style={{ marginTop: 'auto', marginBottom: 'auto' }}>

              <div className="browser-mockup">
                <video muted autoPlay playsInline loop style={{ maxWidth: '100%' }}>
                  <source src={onboardingDemo} type="video/mp4" >
                  </source>
                </video>

              </div>
            </div>
          </div>
          <a href={urls.onboarding} className="thm-btn mt-3 mobile-show" style={{ display: 'none' }}>
            <span>{Texts.GetStarted}</span>
          </a>
        </div>
      </section>
    </>

  );
};
export default Why;
